<template>
    <div class="text-center" v-if="!is_data_ready">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="tab-pane fade show active text-center" v-else-if="lineups.length == 0">
        <p>{{ $t("No Lineup data found") }}.</p>
    </div>
    <div v-else class="ftb_lineup_wrapper">
      <div class="ftb_lineup_mobi_tabs">
        <ul>
          <li>
            <a :class="(activeTeamMobile == 1) ? 'selected': ''" @click="activeTeamMobile = 1">
              {{lineups[0].team}}
            </a>
          </li>
          <li>
            <a :class="(activeTeamMobile == 2) ? 'selected': ''" @click="activeTeamMobile = 2">
              {{lineups[1].team}}
            </a>
          </li>
        </ul>
      </div>
        <div class="ftb_lineup_section" :class="(activeTeamMobile == 1) ? 'team1_active' : 'team2_active'">
            <div class="ftb_lineup_block">
                <h2>
                    {{lineups[0].team}}
                    <!--                    <span>(4-4-2)</span>-->
                </h2>
                <ul>
                    <li v-for="player in lineups[0].players.filter((player)=>player.is_substitute == false)" :key="player.id">
                        <p>
                            {{player.shirt_no}}. {{player.player.firstname}} {{player.player.lastname}}
                            <label v-for=" card in player.cards">
                                <img :src="require(`@/assets/images/${card.type}.webp`)"  :alt="(card.playerName)+ 'Card'" class="lineup_image">
                            </label>
                            <label v-for=" goal in player.goals">
                                <img :src="require(`@/assets/images/${goal.type}.webp`)" alt="Goal" class="lineup_image lineupgoal_pic">
                                <!--<img *ngIf="goal.sub_type == 'Red'" src="/assets/images/red.gif" alt="Red Card" class="lineup_image">-->
                            </label>
                        </p>
                        <span v-if="player.substitutionsIn.length" v-for="sub in player.substitutionsIn" class="lineup_top_arrow">
                            {{sub.minutes}}'
                            <label>
                              <img :src="require('@/assets/images/sabc_lineup_in.webp')" alt="sbac_header_top_ad">
                            </label>
                          </span>
                        <span v-if="player.substitutionsOut.length" v-for="sub in player.substitutionsOut" class="lineup_bottom_arrow">{{sub.minutes}}'
                          <label>
                          <img :src="require('@/assets/images/sabc_lineup_out.webp')" alt="sbac_header_top_ad">
                        </label>
                        </span>
                    </li>
                </ul>
            </div>
            <div class="ftb_lineup_block">
                <h2>
                    {{lineups[1].team}}
                    <!--                  <span> (3-4-3)</span>-->
                </h2>
                <ul>
                    <li v-for="player in lineups[1].players.filter((player)=>player.is_substitute == false)">
                        <p>
                            {{player.shirt_no}}. {{player.player.firstname}} {{player.player.lastname}}
                            <label v-for=" card in player.cards">
                                <img :src="require(`@/assets/images/${card.type}.webp`)"  :alt="(card.playerName)+ 'Card'" class="lineup_image">
                            </label>
                            <label v-for=" goal in player.goals">
                                <img :src="require(`@/assets/images/${goal.type}.webp`)" alt="Goal" class="lineup_image lineupgoal_pic">
                                <!--<img *ngIf="goal.sub_type == 'Red'" src="/assets/images/red.gif" alt="Red Card" class="lineup_image">-->
                            </label>
                        </p>
                        <span v-if="player.substitutionsIn.length" v-for="sub in player.substitutionsIn" class="lineup_top_arrow">{{sub.minutes}}'
                          <label>
                              <img :src="require('@/assets/images/sabc_lineup_in.webp')" alt="sbac_header_top_ad">
                            </label>
                        </span>
                        <span v-if="player.substitutionsOut.length" v-for="sub in player.substitutionsOut" class="lineup_bottom_arrow">{{sub.minutes}}'
                          <label>
                          <img :src="require('@/assets/images/sabc_lineup_out.webp')" alt="sbac_header_top_ad">
                        </label>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="ftb_lineup_section" :class="(activeTeamMobile == 1) ? 'team1_active' : 'team2_active'">
          <div class="ftb_lineup_block">
                <h2>{{$t('football.match-detail.Substitutes')}}</h2>
                <ul>
                  <li v-for="player in lineups[0].players.filter((player)=>player.is_substitute == true)">
                    <p>
                      {{player.player.firstname}} {{player.player.lastname}}
                        <label v-for=" card in player.cards">
                            <img :src="require(`@/assets/images/${card.type}.webp`)"  :alt="(card.playerName)+ 'Card'" class="lineup_image">
                        </label>
                        <label v-for=" goal in player.goals">
                            <img :src="require(`@/assets/images/${goal.type}.webp`)" alt="Goal" class="lineup_image lineupgoal_pic">
                            <!--<img *ngIf="goal.sub_type == 'Red'" src="/assets/images/red.gif" alt="Red Card" class="lineup_image">-->
                        </label>
                    </p>
                      <span v-if="player.substitutionsIn.length" v-for="sub in player.substitutionsIn" class="lineup_top_arrow">{{sub.minutes}}'
                        <label>
                              <img :src="require('@/assets/images/sabc_lineup_in.webp')" alt="sbac_header_top_ad">
                            </label>
                      </span>
                      <span v-if="player.substitutionsOut.length" v-for="sub in player.substitutionsOut" class="lineup_bottom_arrow">{{sub.minutes}}'
                        <label>
                          <img :src="require('@/assets/images/sabc_lineup_out.webp')" alt="sbac_header_top_ad">
                        </label>
                      </span>
                  </li>
                </ul>
            </div>
            <div class="ftb_lineup_block">
                <h2>{{$t('football.match-detail.Substitutes')}}</h2>
                <ul>
                  <li v-for="player in lineups[1].players.filter((player)=>player.is_substitute == true)">
                    <p>
                      {{player.player.firstname}} {{player.player.lastname}}
                        <label v-for=" card in player.cards">
                            <img :src="require(`@/assets/images/${card.type}.webp`)"  :alt="(card.playerName)+ 'Card'" class="lineup_image">
                        </label>
                        <label v-for=" goal in player.goals">
                            <img :src="require(`@/assets/images/${goal.type}.webp`)" alt="Goal" class="lineup_image lineupgoal_pic">
                            <!--<img *ngIf="goal.sub_type == 'Red'" src="/assets/images/red.gif" alt="Red Card" class="lineup_image">-->
                        </label>
                    </p>
                      <span v-if="player.substitutionsIn.length" v-for="sub in player.substitutionsIn" class="lineup_top_arrow">{{sub.minutes}}'
                        <label>
                          <img :src="require('@/assets/images/sabc_lineup_in.webp')" alt="sbac_header_top_ad">
                        </label>

                      </span>
                      <span v-if="player.substitutionsOut.length" v-for="sub in player.substitutionsOut" class="lineup_bottom_arrow">{{sub.minutes}}'
                        <label>
                          <img :src="require('@/assets/images/sabc_lineup_out.webp')" alt="sbac_header_top_ad">
                        </label>
                      </span>
                  </li>
                </ul>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "MatchDetailLineup",
        data() {
            return {
                lineups: [],
                activeTeamMobile: 1,
            };
        },
        mounted() {
            this.getMatches();
            var _this = this;
            this.$root.$on('FBMatchesUpdated', data => {
                if (data.match_ids.includes(_this.$route.params.matchId)) {
                    _this.getMatches()
                }
            });
        },
        beforeDestroy() {
            this.$root.$off('FBMatchesUpdated')
        },
        serverPrefetch() {
            return this.getMatches();
        },
        metaInfo() {
            let ss = this.$store.state.siteSetting;
            if(this.$store.state.fbMatchDetailResponse && this.$store.state.fbMatchDetailResponse.data){
              var replacements = {
                  '%HOME_TEAM%': this.$filterTeam(this.$store.state.fbMatchDetailResponse.data.competitors, true).name,
                  '%AWAY_TEAM%': this.$filterTeam(this.$store.state.fbMatchDetailResponse.data.competitors, false).name,
                  '%DATE%' : this.$route.params.date,
              };
  
              let title = this.$getSafe(() => this.$replacePlaceHolders(ss.football_match_lineup_meta_title,replacements));
              let description = this.$getSafe(() => this.$replacePlaceHolders(ss.football_match_lineup_meta_description,replacements));
              let keywords = this.$getSafe(() => ss.football_match_lineup_meta_keywords);
  
              let canonical = this.$toFullPath(this.$route.fullPath).replace('lineup', 'overview');
  
              return {
                  title: title,
                  description: description,
                  meta: this.$getMetaTags(title, description, keywords),
                  link:[
                      {rel: 'canonical', href: canonical}],
              };
            }
        },
        methods: {
            getMatches() {
                this.is_data_ready = false;
                return this.$axios
                    .get(this.$FB_DETAILS_LINEUPS_API.replace(':match', this.$route.params.matchId))
                    .then(response => {
                        if (response.data) {
                            Object.keys(response.data.lineup).forEach(team => {
                                if (response.data.lineup[team].isHome) {
                                    this.lineups[0] = response.data.lineup[team];
                                } else if(!response.data.lineup[team].isHome) {
                                    this.lineups[1] = response.data.lineup[team];
                                }
                            });
                          this.is_data_ready = true;
                        }
                    })
                    .catch(error => console.log(error))
            },
        }
    };
</script>
<style scoped>
.ftb_lineup_wrapper{background: #ffffff;}
</style>
